import Navbar from './Components/Navbar.js'
import Index from './Sections/Index.js'
import About from './Sections/About.js'
import Skills from './Sections/Skills.js'
import Projects from './Sections/Projects.js'
import Contact from './Sections/Contact.js'


function App() {
  return (
    <div>
      <Navbar/>
      <Index/>
      <About/>
      <Skills/>
      <Projects/>
      <Contact/>

    </div>

  );
}

export default App;

