import React from "react";
import { motion } from "framer-motion";

const MenuItem = ({ menu, index }) => {
    return (
        <a
            href={menu.uri}
            className="h-12 w-12 rounded-full border border-blue-200 flex items-center justify-center group cursor-pointer 
            bg-gradient-to-tr from-gray-700 to-gray-800
            hover:bg-gradient-to-bl hover:from-cyan-900 hover:to-indigo-700
            transition duration-500 ease-in-out transform hover:scale-110"
        >
            <menu.Icon className="text-white text-xl transition-transform duration-600 ease-in-out group-hover:rotate-0 hover:text-black" />
            <motion.div
                initial={{ opacity: 0, y: -10 }}
                whileHover={{ opacity: 1, y: 28 }}
                transition={{ duration: 0.5 }}
                className="absolute mt-4 px-2 py-1 text-white text-sm font-bold rounded opacity-0 group-hover:opacity-100"
            >
                {menu.name}
            </motion.div>
        </a>
    );
};

export default MenuItem;
