import React from 'react';
import { motion } from 'framer-motion';

const skills = [
    { name: 'Java', icon: '👨‍💻' },
    { name: 'Python', icon: '🐍' },
    { name: 'C', icon: '🖥️' },
    { name: 'HTML5', icon: '🌐' },
    { name: 'CSS3', icon: '🎨' },
    { name: 'React.js', icon: '⚛️' },
    { name: 'Spring Boot', icon: '☕' },
    { name: 'MySQL', icon: '🗃️' },
    { name: 'MongoDB', icon: '📦' },
    { name: 'Jira', icon: '📊' },
    { name: 'GitHub', icon: '🧑‍💻' },
    { name: 'Tailwind CSS', icon: '⚡' },
    { name: 'Postman', icon: '💻' }, // Using a truck emoji for Postman (API testing)
];

function Skills() {
    return (
        <section
            id="skills"
            className="w-full flex flex-col items-center justify-center gap-12 relative text-white bg-gray-900 py-20 px-6"
        >
            {/* Title Section */}
            <div className="flex items-center justify-center text-left mb-4">
                <motion.h1
                    className="text-4xl sm:text-5xl font-bold font-custom text-center"
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 1.5, ease: 'easeInOut'}}
                >
                    My{' '}
                    <motion.span
                        className="text-indigo-400"
                        initial={{scale: 0.8, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        transition={{duration: 1.2, delay: 0.5, ease: 'easeOut'}}
                        whileHover={{scale: 1.2, transition: {duration: 0.3}}}
                    >
                        Skills
                    </motion.span>
                </motion.h1>
            </div>

            {/* Skill Icons Section */}
            <div className="flex flex-wrap justify-center gap-8">
                {skills.map((skill, index) => (
                    <motion.div
                        key={index}
                        className="flex flex-col items-center justify-center text-center"
                        initial={{opacity: 0, y: 50}}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: {delay: index * 0.1, duration: 0.8},
                        }}
                        whileHover={{
                            scale: 1.1,
                            transition: {duration: 0.3, ease: 'easeInOut'},
                        }}
                    >
                        <motion.div
                            className="text-4xl sm:text-5xl mb-4"
                            whileHover={{ rotate: 15, transition: { duration: 0.3 } }}
                        >
                            {skill.icon}
                        </motion.div>
                        <motion.p
                            className="text-lg font-medium text-gray-300"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                        >
                            {skill.name}
                        </motion.p>
                    </motion.div>
                ))}
            </div>
        </section>
    );
}

export default Skills;
