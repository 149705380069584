import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const BackgroundScene = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            25,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        camera.position.z = 24;

        const renderer = new THREE.WebGLRenderer({
            canvas: canvas,
            antialias: true,
            alpha: true,
        });
        renderer.setSize(window.innerWidth * 0.9, window.innerHeight); // Set width to 90%
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;

        // Setup spheres and cubes with animation properties
        const objects = [];
        const count = 50; // Total objects (mix of spheres and cubes)

        for (let i = 0; i < count; i++) {
            const isSphere = Math.random() > 0.5; // Randomly choose between sphere or cube
            const size = Math.random() * 1.5 + 0.2; // Random size
            const geometry = isSphere
                ? new THREE.SphereGeometry(size, 32, 32)
                : new THREE.BoxGeometry(size, size, size);

            const material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(
                    Math.random(), // Red channel
                    Math.random(), // Green channel
                    Math.random()  // Blue channel
                ),
                metalness: 0.7,
                roughness: 0.3,
            });

            const mesh = new THREE.Mesh(geometry, material);

            // Random positions
            mesh.position.set(
                (Math.random() - 0.5) * 20,
                (Math.random() - 0.5) * 20,
                (Math.random() - 0.5) * 20
            );

            // Add speed for animation
            mesh.userData = {
                speed: {
                    x: (Math.random() - 0.5) * 0.02,
                    y: (Math.random() - 0.5) * 0.02,
                    z: (Math.random() - 0.5) * 0.02,
                },
            };

            objects.push(mesh);
            scene.add(mesh);
        }

        // Add multiple colored lights
        const lights = [
            { color: 0xff0000, position: [10, 10, 10] },
            { color: 0x00ff00, position: [-10, 10, -10] },
            { color: 0x0000ff, position: [10, -10, 10] },
            { color: 0xffff00, position: [-10, -10, -10] },
        ];

        lights.forEach(({ color, position }) => {
            const light = new THREE.PointLight(color, 1.5, 50);
            light.position.set(...position);
            scene.add(light);
        });

        // Animation loop
        const animate = () => {
            objects.forEach((obj) => {
                // Animate positions
                obj.position.x += obj.userData.speed.x;
                obj.position.y += obj.userData.speed.y;
                obj.position.z += obj.userData.speed.z;

                // Bounce back if reaching boundaries
                if (Math.abs(obj.position.x) > 10) obj.userData.speed.x *= -1;
                if (Math.abs(obj.position.y) > 10) obj.userData.speed.y *= -1;
                if (Math.abs(obj.position.z) > 10) obj.userData.speed.z *= -1;
            });

            controls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };
        animate();

        // Handle window resize
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth * 0.9, window.innerHeight); // Maintain 90% width
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            renderer.dispose();
            scene.clear();
        };
    }, []);

    return (
        <canvas
            ref={canvasRef}
            id="webgl"
            style={{ display: 'block', width: '98%' }}
        />
    );
};

export default BackgroundScene;
