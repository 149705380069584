import {
FaDiagramProject,
FaEnvelope,
FaFacebookF,
FaGithub,
FaHouse,
FaLinkedinIn,
FaRenren,
FaUser,
FaYoutube,
} from "react-icons/fa6";

// import {
// ChatApp,
// CodePenClone,
// FreshJuiceUI,
// ImageSharing,
// OpenAI,
// PixabayClone,
// PortfolioFirebase,
// RestaurantClone,
// SocialMedia,
// } from "../Assets";

export const Socials = [
{
    id: `facebook-001`,
    Icon: FaFacebookF,
    uril: "",
    color: "#1877F2",
},
{
    id: `linkedin-002`,
    Icon: FaLinkedinIn,
    uril: "",
    color: "#0072b1",
},
{
    id: `github-003`,
    Icon: FaGithub,
    uril: "",
    color: "#fff",
},
{
    id: `youtube-004`,
    Icon: FaYoutube,
    uril: "",
    color: "#ff0000",
},
];

export const Menus = [
{
    id: `index-001`,
    Icon: FaHouse,
    uri: "#home",
    name: "Home",
},
{
    id: `about-002`,
    Icon: FaUser,
    uri: "#about",
    name: "About",
},
{
    id: `skills-003`,
    Icon: FaRenren,
    uri: "#skills",
    name: "Skills",
},
{
    id: `projects-004`,
    Icon: FaDiagramProject,
    uri: "#projects",
    name: "Projects",
},
{
    id: `contact-005`,
    Icon: FaEnvelope,
    uri: "#contact",
    name: "Contact",
},
];

// export const ProjectsData = [
// {
//     id: `food-${Date.now()}`,
//     name: "Single Food Restaurant",
//     imgSrc: RestaurantClone,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `codepen-${Date.now()}`,
//     name: "CodePen Clone",
//     imgSrc: CodePenClone,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `openai-${Date.now()}`,
//     name: "OpenAI",
//     imgSrc: OpenAI,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `chatapp-${Date.now()}`,
//     name: "Chat App Build",
//     imgSrc: ChatApp,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `imageSharing-${Date.now()}`,
//     name: "Image Sharing App",
//     imgSrc: ImageSharing,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `pixabayclone-${Date.now()}`,
//     name: "Pixabay Clone 2.0",
//     imgSrc: PixabayClone,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `freshjuiceui-${Date.now()}`,
//     name: "Fresh Juice UI Build",
//     imgSrc: FreshJuiceUI,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `socialmedia-${Date.now()}`,
//     name: "Social Media App",
//     imgSrc: SocialMedia,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// {
//     id: `portfoliofirebase-${Date.now()}`,
//     name: "Portfolio UI Fireabse",
//     imgSrc: PortfolioFirebase,
//     gitURL: "https://github.com/Vetrivel-VP",
// },
// ];