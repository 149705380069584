import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-[#1F1F39] text-gray-500 py-4">
            <div className="text-center text-sm ">
                © 2024 All rights reserved by <span className="font-bold text-blue-500">Mohamad Siyam</span>
            </div>
        </footer>
    );
};

export default Footer;
