import React from 'react';
import { motion } from 'framer-motion';
import prfl1 from "../Assets/prfl1.jpg";

const StickyProfile = () => {

        return (
        <div className="sticky top-6 flex items-start gap-4 z-10">
            {/* Outer Ring with Border Shadow and Progress Animation */}
            <div
                className="relative w-28 h-28 sm:w-24 sm:h-24 md:w-28 md:h-28 rounded-full flex items-center justify-center border-2 border-transparent shadow-lg hover:scale-105 transition-all duration-300">
                {/* Circular Progress Bar */}
                <motion.div
                    className="absolute top-0 left-0 w-full h-full rounded-full border-2 border-blue-500"
                    animate={{scale: [1, 1.1, 1]}}
                    transition={{duration: 3, repeat: Infinity, repeatType: "reverse"}}
                />
                <motion.div
                    className="absolute top-0 left-0 w-full h-full rounded-full border-2 border-grey-900"
                    animate={{scale: [1.1, 1.2, 1.1]}}
                    transition={{duration: 1, repeat: Infinity, repeatType: "reverse"}}
                />
                {/* Profile Image */}
                <div className="w-full h-full rounded-full overflow-hidden">
                    <img
                        src={prfl1}
                        alt="profileImg"
                        className="w-full h-full object-cover rounded-full shadow-lg"
                    />
                </div>
            </div>

            {/* Profile Name */}
            <div>
                <h1 className="text-2xl sm:text-xl md:text-2xl font-bold text-white mt-8">
                    Mohamad Siyam
                </h1>
            </div>
        </div>
        );
};

export default StickyProfile;
