import React, { useEffect, useState, useCallback } from "react";
import initializeMetaballs from "../Components/Metaball";
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../index.css';
import StickyProfile from "../Components/StickyProfile";

function Index() {
    const [loading, setLoading] = useState(true);

    // Use useCallback to avoid reinitializing the animation on every render
    const initializeWebGL = useCallback(() => {
        initializeMetaballs();
    }, []);

    useEffect(() => {
        // Initialize WebGL animation
        initializeWebGL();

        // Simulate a loading delay
        const timer = setTimeout(() => setLoading(false), 500);
        return () => clearTimeout(timer); // Clean up on unmount
    }, [initializeWebGL]);

    return (
        <>
            {loading ? (
                <div className="flex items-center justify-center h-screen">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-white"></div>
                </div>
            ) : (
                <section
                    id="home"
                    className="flex flex-col relative text-white h-screen p-6"
                >
                    {/* Profile Section */}
                    <StickyProfile/>

                    {/* Main Content */}
                    <div className="flex items-center justify-center text-left mt-16">
                        <h1
                            className="text-5xl lg:text-6xl font-bold mb-2 text-white"
                            style={{
                                WebkitTextStroke: '1px black',
                                textStroke: '1px black',
                            }}
                        >
                            Hi, I'm Mohamad Siyam
                        </h1>
                    </div>
                    <div className="grid justify-items-left sm:justify-items-left lg:justify-items-center mb-4 text-left lg:text-center">
                        <h2 className="text-xl sm:text-2xl font-medium">
                            Software Engineering Undergraduate
                        </h2>
                        <p className="text-base sm:text-lg text-gray-300">
                            BICT(Hons) | University of Ruhuna
                        </p>
                    </div>

                    <div className="mt-2 flex flex-wrap gap-4 justify-center mt-12">
                        <a
                            href="https://github.com/MohamadSiyam66"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-l py-2 px-4 rounded-3xl bg-gray-800 text-white border-2 border-amber-50 hover:border-gray-400 hover:bg-gray-700 hover:text-gray-100 transition duration-300 ease-in-out"
                        >
                            GitHub
                        </a>
                        <a
                            href="https://www.linkedin.com/in/mohamad-siyam-a7b399173/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-l py-2 px-4 rounded-3xl bg-blue-600 text-white border-2 border-amber-50 hover:border-blue-400 hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out"
                        >
                            LinkedIn
                        </a>
                        <a
                            href="/Mohamad_Siyam.pdf"
                            download="Mohamad_Siyam_CV.pdf"
                            className="text-l py-2 px-4 rounded-3xl bg-green-600 text-white border-2 border-amber-50 hover:border-green-400 hover:bg-green-500 hover:text-white transition duration-300 ease-in-out"
                        >
                            Download CV
                        </a>
                    </div>

                </section>
            )}
        </>
    );
}

export default Index;
