import React, { useState } from 'react';
import { FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import BackgroundScene from '../Components/BackgroundScene';
import Footer from '../Components/Footer';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [validationStatus, setValidationStatus] = useState({
        name: null,
        email: null,
        phone: null,
    });

    const [popupMessage, setPopupMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        let isValid = false;

        if (name === 'name') {
            isValid = value.trim().length > 2;
        } else if (name === 'email') {
            isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        } else if (name === 'phone') {
            isValid = /^\d{10}$/.test(value);
        }

        setValidationStatus((prevStatus) => ({
            ...prevStatus,
            [name]: isValid,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submitting
        if (
            !formData.name.trim() ||
            !formData.email.trim() ||
            !formData.phone.trim() ||
            !formData.message.trim()
        ) {
            setPopupMessage({ type: 'error', message: 'Enter all details and send the message!' });
            setTimeout(() => setPopupMessage(null), 2000);
            return;
        }

        const finalData = {
            ...formData,
            message: `Name: ${formData.name}, Email: ${formData.email}, Phone: ${formData.phone}\n\nMessage: ${formData.message}`,
        };

        emailjs
            .send(
                'service_cui8djd',
                'template_j3swpy1',
                finalData,
                'Ev3AzNG1PEcTs5oyV'
            )
            .then(() => {
                setPopupMessage({ type: 'success', message: 'Message successfully sent!' });

                // Reset the form
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                });

                setValidationStatus({
                    name: null,
                    email: null,
                    phone: null,
                });

                setTimeout(() => setPopupMessage(null), 2000);
            })
            .catch(() => {
                setPopupMessage({ type: 'error', message: 'Failed to send the message!' });
                setTimeout(() => setPopupMessage(null), 2000);
            });
    };

    const ContactInfo = ({ icon: Icon, text, link }) => (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-4 hover:scale-105 transition-transform duration-300"
        >
            <Icon
                size={28}
                className="text-[#4158D0] hover:text-white transition duration-200 transform hover:scale-110"
            />
            <p className="text-lg font-semibold">{text}</p>
        </a>
    );

    return (
        <div className="relative min-h-screen w-full sm:h-[85vh] sm:w-[100%]">
            <BackgroundScene />
            {popupMessage && (
                <div
                    className={`fixed top-4 right-4 px-4 py-3 rounded-3xl shadow-lg text-white ${
                        popupMessage.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                    }`}
                >
                    {popupMessage.message}
                </div>
            )}
            <section
                id="contact"
                className="absolute inset-0 flex items-center justify-center py-12"
            >
                <div className="relative flex flex-col md:flex-row justify-between rounded-2xl shadow-2xl w-[90%] max-w-5xl text-white border border-opacity-50 border-[#4e4e7f] bg-transparent bg-opacity-80 backdrop-blur-md transform transition-all duration-500">
                    <div className="text-white p-8 flex flex-col justify-center items-start w-full md:w-1/2 text-left">
                        <h2 className="text-4xl font-extrabold mb-8 text-[#C850C0]">
                            Get in Touch
                        </h2>
                        <div className="space-y-8">
                            <ContactInfo
                                icon={FaEnvelope}
                                text="mhmdsiyam66@gmail.com"
                                link="mailto:mhmdsiyam66@gmail.com"
                            />
                            <ContactInfo
                                icon={FaPhone}
                                text="+94 75-9290405"
                                link="tel:0759290405"
                            />
                            <ContactInfo
                                icon={FaWhatsapp}
                                text="+94 76-4921315"
                                link="https://wa.me/94764921315"
                            />
                        </div>
                    </div>
                    <div className="bg-transparent p-10 w-full sm:w-3/4 md:w-1/2 flex flex-col justify-center shadow-lg border border-[#C850C0] hover:shadow-2xl transition-shadow duration-300 rounded-xl">
                        <h2 className="text-3xl font-bold mb-8 text-center text-[#C850C0]">
                            Send Me a Message
                        </h2>
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <InputField
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                value={formData.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={validationStatus.name}
                            />
                            <InputField
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={validationStatus.email}
                            />
                            <InputField
                                type="number"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={validationStatus.phone}
                            />
                            <TextareaField
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                            <button
                                type="submit"
                                className="w-full py-3 bg-gradient-to-r from-[#C850C0] to-[#4158D0] text-white font-semibold rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>

    );
}

const InputField = ({ type, name, placeholder, value, onChange, onBlur, isValid }) => (
    <div className="relative">
        <input
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            className={`w-full px-4 py-3 bg-[#1F1F39] bg-opacity-30 text-white rounded-lg focus:outline-none focus:ring-4 transition-all ${
                isValid === false
                    ? 'ring-red-500'
                    : isValid === true
                        ? 'ring-green-500'
                        : 'focus:ring-[#C850C0]'
            }`}
        />
        {isValid && <span className="absolute right-3 top-3 text-green-500 font-bold">✔</span>}
        {isValid === false && <span className="absolute right-3 top-3 text-red-500 font-bold">✖</span>}
    </div>
);

const TextareaField = ({ name, placeholder, value, onChange }) => (
    <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows="2"
        className="w-full px-4 py-3 bg-[#1F1F39] bg-opacity-30 text-white rounded-lg focus:outline-none focus:ring-4 focus:ring-[#C850C0] focus:ring-opacity-50 transition-all hover:bg-opacity-50 hover:border-[#C850C0] hover:scale-105"
    ></textarea>
);

export default Contact;
