import React from 'react';
import { motion } from 'framer-motion';
import prfl2 from '../Assets/prfl2.jpg';

const About = () => {
    return (
        <section
            id="about"
            className="w-full flex flex-col items-center justify-start gap-6 text-white bg-gray-900 px-6 min-h-screen"
        >
            {/* Title Section */}
            <div className="flex items-center justify-center text-left mt-8 mb-6">
                <motion.h1
                    className="text-4xl sm:text-5xl font-bold font-custom text-center"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1.5, ease: 'easeInOut' }}
                >
                    About{' '}
                    <motion.span
                        className="text-indigo-400"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 1.2, delay: 0.5, ease: 'easeOut' }}
                        whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
                    >
                        Me
                    </motion.span>
                </motion.h1>
            </div>

            {/* Content Section */}
            <div className="flex flex-col md:flex-row w-full max-w-6xl gap-8">
                {/* Left Column: Image */}
                <motion.div
                    className="w-full md:w-1/4 h-[50vh] md:h-[70vh] flex items-center justify-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <motion.img
                        src={prfl2}
                        alt="Mohamad Siyam"
                        className="w-full h-full object-cover shadow-lg transition-transform rounded-3xl border-4 border-transparent shadow-indigo-500/50"
                    />
                </motion.div>

                {/* Right Column: About Me, My Journey, My Interests */}
                <div className="w-full md:w-3/4 flex flex-col gap-8">
                    {/* About Me Description */}
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        className="p-6 rounded-xl shadow-lg transition-transform duration-300 border-2 border-transparent hover:border-indigo-400 shadow-indigo-500/50"
                    >
                        <h2 className="text-xl font-semibold text-center mb-4">Overview</h2>
                        <p className="text-sm text-blue-300 text-left">
                            Hi, I'm <b>Mohamad Siyam</b>, a 4th-year Software Engineering student at the University of Ruhuna.
                            I'm passionate about solving real-world problems through technology, with expertise in Java and web technologies like React, Spring Boot, and Tailwind CSS.
                            I thrive in both individual and team environment, always eager to learn, innovate, and contribute to impactful projects.
                            My goal is to continuously improve my skills and apply them to create solutions that make a positive impact.
                        </p>
                    </motion.div>

                    {/* My Journey */}
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        className="p-6 rounded-xl shadow-lg transition-transform duration-300 border-2 border-transparent hover:border-indigo-400 shadow-indigo-500/50"
                    >
                        <h2 className="text-xl font-semibold text-center mb-4">My Journey 🚀</h2>
                        <p className="text-sm text-gray-300 text-center">
                            From learning coding basics to building full-stack projects, I’m driven to create efficient solutions while growing as a developer.
                            I've worked on projects like Learning Management System, Salon Management System, Electricity Bill Management System and more...
                        </p>
                    </motion.div>

                    {/* My Interests */}
                    <motion.div
                        whileHover={{ scale: 1.05 }}
                        className="p-6 rounded-xl shadow-lg transition-transform duration-300 border-2 border-transparent hover:border-indigo-400 shadow-indigo-500/50"
                    >
                        <h2 className="text-xl font-semibold text-center mb-4">My Interests 🌟</h2>
                        <p className="text-sm text-gray-300 text-center">
                            I love exploring new tools and frameworks to improve my skill set. Beyond coding, I enjoy cricket, athletics, photography, and staying updated on the latest tech trends.
                        </p>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default About;
