import React from 'react';
import { Menus } from '../Utilities/helpers';
import Item from '../Components/Item';

function Navbar() {
    return (
        <div className="fixed lg:right-0 lg:top-0 bottom-0 right-0 w-full h-auto lg:h-screen lg:w-[7vw] flex justify-center items-end lg:items-center pb-3 lg:pb-0 z-50">
            <div className="px-2 py-2 lg:py-12 rounded-full flex flex-row lg:flex-col justify-center items-center gap-6 lg:gap-10 duration-500 backdrop-blur-md bg-white/5 shadow-blue">
                {Menus?.map((item, index) => (
                    <Item key={index} menu={item} index={index} />
                ))}
            </div>
        </div>
    );
}

export default Navbar;

